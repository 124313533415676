<template>
  <div>
    <html-fragment :html="sanitizeContent(value)"></html-fragment>
  </div>
</template>

<script>
import HtmlFragment from "@/components/FunctionalHtmlReplace";
import sanitizeContentMixin from "@/mixins/sanitizeContentMixin.js";

export default {
  mixins: [sanitizeContentMixin],
  components: {
    HtmlFragment,
  },
  props: {
    value: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
